import { hashconnect, initHashconnect, hashconnectSessionData } from './initHashconnect.js'
import { Transaction } from '@hashgraph/sdk'
//import { getConfig } from './utils'

// var intervalId

export default async (bladeSigner) => {


    // document.getElementById('last_tx').innerHTML = '' // reset
    document.getElementById('last_tx').innerHTML = `<BR><div class='alert alert-info' role='alert' id='last_tx_result'>⏳ Please wait...</div>`
    // saas may need some seconds to check api conditions etc, before providing bytes
            
            
// ┌─┐┌─┐┌┬┐┬ ┬┌─┐┬─┐  ┌─┐┌─┐┬─┐┌─┐┌┬┐┌─┐┌┬┐┌─┐┬─┐┌─┐
// │ ┬├─┤ │ ├─┤├┤ ├┬┘  ├─┘├─┤├┬┘├─┤│││├┤  │ ├┤ ├┬┘└─┐
// └─┘┴ ┴ ┴ ┴ ┴└─┘┴└─  ┴  ┴ ┴┴└─┴ ┴┴ ┴└─┘ ┴ └─┘┴└─└─┘
// gather parameters needed to process the request

    //network is global
    
    //but using local meanwhile.. todo refactor
	const urlParamsLocal_HR65476 = new URLSearchParams(window.location.search)
    
	let dealId = document.getElementById('dealId')?.value ?? urlParamsLocal_HR65476.get('dealId') ?? false
    
    if (!dealId) {
        // temp fudge to handle pretty url (needed for hashpack deep link compatibility)
        const url = new URL(window.location.href)
        const match = url.pathname.match(new RegExp(`/dealId/([^/]+)`))
        
        dealId = match?.[1] ?? false
        
        //untested
        //dealId = (match => match?.[1] ?? false)(url.pathname.match(/\/dealId\/([^/]+)/))

    }

	console.log('dealId=', dealId)

	// if (urlParams.has('dealId')) dealId = urlParams.get('dealId')
		// else console.log('missing dealId')

	// get userAccountId from session storage 
    const hashconnectSessionData = JSON.parse(window.localStorage.getItem('hashconnectSessionData'))
	let userAccountId = hashconnectSessionData
		? hashconnectSessionData.pairedWallets[hashconnectSessionData.activeWalletIndex]
		: bladeSigner.getActiveAccountId()

	console.log("user account id: ", userAccountId)
    var data = {
        network: network,
        userAccountId: userAccountId,
		dealId: dealId,
		user_parameters: {}
    }


// ┬─┐┌─┐┌─┐┌┬┐  ┬ ┬┌─┐┌─┐┬─┐  ┬┌┐┌┌─┐┬ ┬┌┬┐┌─┐
// ├┬┘├┤ ├─┤ ││  │ │└─┐├┤ ├┬┘  ││││├─┘│ │ │ └─┐
// ┴└─└─┘┴ ┴─┴┘  └─┘└─┘└─┘┴└─  ┴┘└┘┴  └─┘ ┴ └─┘
// read user inputs


	// Get all elements with the "user_input" class
	var inputElements = document.querySelectorAll('.user_input');

	// Loop through each element and get its ID and value
	for (var i = 0; i < inputElements.length; i++) {
	  var elementId = inputElements[i].id;
	  var elementValue = inputElements[i].value;
	  
	  // Do something with the name and value
	  console.log('ID: ' + elementId + ', Value: ' + elementValue);
	  data['user_parameters'][elementId] = elementValue;
	}


// ┌─┐┌─┐┌┬┐  ┌┬┐─┐ ┬
// │ ┬├┤  │    │ ┌┴┬┘
// └─┘└─┘ ┴    ┴ ┴ └─
// get tx

	// pass network, userAccountId, and dealID to the signer
    
    
	let api_sign = './vendor/hashconnect/tx_signer.php'
    const saas_domain = urlParamsLocal_HR65476.get('saas_domain') ?? false
    if (saas_domain) api_sign += '?domain=' + saas_domain // sanitised on the server, plus admin ACL. For dev tests

    const response = await fetch(api_sign, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    })

    //const { bytes } = await response.json()  // get the transaction ready for signing
	const result = await response.json()  // get the transaction ready for signing
	console.log(result)
    const nodeAccountId = result?.nodes?.[0] || 'error RU84742'

    const nodeName = getNodeName(nodeAccountId)
    const nodeComment = `Node ${nodeAccountId} ${nodeName}`
    console.log(`attempting with node ${nodeComment}`)
    
	if (result.error)
		document.getElementById('last_tx').innerHTML = `<BR><BR><div class='alert alert-warning' role='alert' id='last_tx_result'>${result.error}</div>`

// ┌─┐┬ ┬┌┬┐┬ ┬┬─┐┌─┐  ┌┬┐┌─┐┌┬┐┌─┐┌┬┐┌─┐
// ├┤ │ │ │ │ │├┬┘├┤    ││├─┤ │ ├┤  ││ ┌┘
// └  └─┘ ┴ └─┘┴└─└─┘  ─┴┘┴ ┴ ┴ └─┘─┴┘ o 
// future dated? isScheduled	

	function getStartTimeInfo(txid) {
	  const validStart = parseInt(txid.split('@')[1])
	  const validStartStr = new Date(validStart * 1000).toISOString().replace('T', ' ').substr(0, 19)
	  const timenow = Math.floor(Date.now() / 1000)
	  const secondsRemaining = validStart - timenow

	  let validStatus = 'unknown'
	  if (secondsRemaining > -120 && secondsRemaining < 0) validStatus = 'valid'
	  if (secondsRemaining > 0) validStatus = 'not_yet_valid'
	  if (secondsRemaining < -120) validStatus = 'expired'

	  return { validStart, validStartStr, secondsRemaining, validStatus }
	}

	const txid = result.txid
	console.log(`txid = ${txid}`) 
	const startTimeInfo = getStartTimeInfo(result.txid)
	const startseconds = startTimeInfo.validStart
	
	//let validSeconds = txid.split("@")[1];
	//const startseconds = result.request?.startseconds ?? null
	//let isScheduled = (startseconds != null)
	let isScheduled = (startTimeInfo.validStatus == 'not_yet_valid')
	console.log(`txid = ${txid} status is ${startTimeInfo.validStatus} secondsRemaining is ${startTimeInfo.secondsRemaining} validStart is ${startTimeInfo.validStart}`)
	
	
	let user_message
	if (isScheduled) { 
		
		let txid = result.txid
		let validSeconds = txid.split("@")[1];

		let validDate = new Date( validSeconds *1000);
		let validDateStr = validDate.toGMTString()
		
		//+"<br>"+validDate.toLocaleString());


		// var nowInSeconds = Math.floor(Date.now() / 1000);
		// console.log(nowInSeconds);
		// var remainingSeconds = validSeconds - nowInSeconds
		// var remainingTime = formatDuration(remainingSeconds)

		let link_txid = `<a href='./future?network=${network}&txid=${result.txid}' target='${result.txid}'>${result.txid}</a>`
		
		// beware, maybe edge case issue with txid, but no issue showing atm
		user_message = '🕒 Transaction has been scheduled with ID ' + link_txid + '<br>for ' + validDateStr + '<BR><BR><span id="time_remaining"></span>'
		console.log(user_message)
	
		document.getElementById('last_tx').innerHTML = `<BR><BR><div class='alert alert-info' role='alert' id='last_tx_result'>${user_message}</div>`	

		var endTime = parseInt(validSeconds, 10)
        let countdownIntervalId
		if (countdownIntervalId) clearInterval(countdownIntervalId)			
		countdownIntervalId = setInterval(function() {updateRemainingTime(endTime)}, 1000)
		
	}
	
	const { bytes } = result;
	//console.log(bytes)
	
// ┌─┐┌─┐┌┐┌┌┬┐  ┌┬┐─┐ ┬  ┌┬┐┌─┐  ┬ ┬┌─┐┌─┐┬ ┬┌─┐┌─┐┌─┐┬┌─
// └─┐├┤ │││ ││   │ ┌┴┬┘   │ │ │  ├─┤├─┤└─┐├─┤├─┘├─┤│  ├┴┐
// └─┘└─┘┘└┘─┴┘   ┴ ┴ └─   ┴ └─┘  ┴ ┴┴ ┴└─┘┴ ┴┴  ┴ ┴└─┘┴ ┴

    document.getElementById('last_tx').innerHTML = (result.error) 
    ? `<BR><div class='alert alert-warning' role='alert' id='last_tx_result'>📲 Check your wallet, but note warnings below...<BR><BR>${result.error}</div>`
    : `<BR><div class='alert alert-primary' role='alert' id='last_tx_result'>📲 Check your wallet...</div>`

	let sendTxRes
    let submitServerSide = urlParamsLocal_HR65476.get('submitServerSide') ?? false  // only doing for hashpack atm
    // let submitServerSide = urlParamsLocal_HR65476.get('submitServerSide') ?? true  // consider making this the default
    // especially if implementing linked actions from deal eg onSuccess.. onError..
    
    let returnTransaction = isScheduled || submitServerSide
    
	// send tx to hashpack
	if (hashconnectSessionData) {
		console.log("Paying with hashconnect")
		// initialise hashconnect obj and get returned data
		const initData = await initHashconnect(hashconnect)
			
		const transData = {
			topic: initData.topic,
			byteArray: bytes,
			metadata: {
				accountToSign: hashconnectSessionData.pairedWallets[hashconnectSessionData.activeWalletIndex],
				returnTransaction: returnTransaction
			}
		}

		// ask the user to sign
		sendTxRes = await hashconnect.sendTransaction(initData.topic, transData)
	} 
	// handle tx with blade
	else {
		console.log("Paying with blade")   // todo, refactor how to organise Hashpack vs Blade
		const tx = Transaction.fromBytes(new Uint8Array(bytes.data))
		if (isScheduled) {	
			console.log("sign only with blade - tbc untested")
			const [signedTransaction, transactionId] = await bladeSigner.signAndReturnBytes(tx).catch(err => console.error("Error returning bytes"))

			sendTxRes = { // roughly match hashconnect response
				//network: network,  // not needed, as server side php has this
				success: true, //? tbc
				transactionId,
				signedTransaction,
				raw: JSON.stringify({transactionId, signedTransaction}) // debug only
			}

		} else { // execute now
			console.log("execute with blade - tested ok")
			const bladeTxRes = await bladeSigner.call(tx).catch(err => {throw new Error(err)})
			console.log(bladeTxRes)

			sendTxRes = {
				success: true, //? tbc
				response: {
					transactionId: bladeTxRes.transactionId.toString()
				}
			}
		}
	}
    
	console.log(sendTxRes)
	
    sendTxRes.success
        ? console.log('Signed successfully')
        : console.log('Error', sendTxRes)

	

// ┌─┐┌─┐┬  ┬┌─┐  ┬─┐┌─┐┌─┐┌─┐┌─┐┌┐┌┌─┐┌─┐
// └─┐├─┤└┐┌┘├┤   ├┬┘├┤ └─┐├─┘│ ││││└─┐├┤ 
// └─┘┴ ┴ └┘ └─┘  ┴└─└─┘└─┘┴  └─┘┘└┘└─┘└─┘
// save response if scheduled

//hmm we need to update tx_save_result.php to send data back to saas
	// save on the server
    
    if (isScheduled) {
        await saveTransaction(sendTxRes)
        let alert_type = sendTxRes.success ? 'alert alert-success' : 'alert alert-secondary'
        document.getElementById('last_tx').innerHTML = `<BR><div class='${alert_type}' role='alert' id='last_tx_result'>${user_message}</div>`
        return
    }


// ┬ ┬┌─┐┌─┐┬─┐  ┬─┐┌─┐ ┬┌─┐┌─┐┌┬┐┌─┐┌┬┐
// │ │└─┐├┤ ├┬┘  ├┬┘├┤  │├┤ │   │ ├┤  ││
// └─┘└─┘└─┘┴└─  ┴└─└─┘└┘└─┘└─┘ ┴ └─┘─┴┘
// user rejected, and not scheduled
    if (!sendTxRes.success) {
        await saveTransaction(sendTxRes)

        let errorMsg =  (sendTxRes.error == 'USER_REJECT') ? '🚫 Cancelled' : '⚠️ FJ87423 tx failed<BR><pre>' + JSON.stringify(sendTxRes.error) + '</pre>'
        console.log(sendTxRes)
        // getNodeName
        
        document.getElementById('last_tx').innerHTML = `<BR><div class='alert alert-secondary' role='alert' id='last_tx_result'>${errorMsg}<BR>${nodeComment}</div>`
        return
    }


// ┌─┐┬ ┬┌┐ ┌┬┐┬┌┬┐  ┌┬┐─┐ ┬  ┌─┐┌─┐┬─┐┬  ┬┌─┐┬─┐  ┌─┐┬┌┬┐┌─┐
// └─┐│ │├┴┐││││ │    │ ┌┴┬┘  └─┐├┤ ├┬┘└┐┌┘├┤ ├┬┘  └─┐│ ││├┤ 
// └─┘└─┘└─┘┴ ┴┴ ┴    ┴ ┴ └─  └─┘└─┘┴└─ └┘ └─┘┴└─  └─┘┴─┴┘└─┘
// submit tx server side	

if (submitServerSide) {
    
    // document.getElementById('last_tx').innerHTML = `<BR><div class='alert alert-secondary' role='alert' id='last_tx_result'>⌛ Please wait a second...</div>`

    let waitseconds = 5
    let timeleft
    const intervalId = setInterval(() => {
      waitseconds--
      timeleft = (waitseconds > 1) ? `${waitseconds} seconds` : 'a second...'
      document.getElementById('last_tx_result').innerHTML = `⌛ Please wait ${timeleft}`
      if (waitseconds <= 0) clearInterval(intervalId)
    }, 1000)


    let url_submit = './vendor/hashconnect/tx_submit.php'
    const res_submit = await fetch(url_submit, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(sendTxRes),
    })
        
	console.log('YU76643 result of submitting tx...')
    const responseData = await res_submit.json()
    console.dir({url_submit, responseData})
    
    
    let last_tx_id = responseData.transactionId ?? false
    let tx_status = responseData.status ?? false
	let url_last_tx = `https://kpay.uk/tx/${network}/${last_tx_id}`
    
    
    let last_tx_result_message = (tx_status == 'SUCCESS') ? 'Success! ☑️' : '⚠️'
    let last_tx_alert_type     = (tx_status == 'SUCCESS') ? 'alert alert-success' : 'alert alert-warning'

    if (intervalId) clearInterval(intervalId)
    document.getElementById('last_tx').innerHTML = `<BR><div class='${last_tx_alert_type}' role='alert' id='last_tx_result'>${last_tx_result_message} Please wait a moment...</div>`
    document.getElementById('last_tx_result').innerHTML = `${last_tx_result_message} See <a href='${url_last_tx}' target='mirror'>receipt</a>`
        
    // document.getElementById('last_tx').innerHTML = `<BR><BR><div class='${alert_type}' role='alert' id='last_tx_result'>${user_message}</div>`
    
    return
        
} // else carry on as before

    
	let last_tx_id = sendTxRes.response.transactionId
	

	last_tx_id = last_tx_id.replace("@", "-")
	last_tx_id = last_tx_id.replace("@", "-")
	last_tx_id = last_tx_id.replace("0.0.", "bollocks to hashscan")
	last_tx_id = last_tx_id.replace(".", "-")
	last_tx_id = last_tx_id.replace("bollocks to hashscan", "0.0.")


	let url_last_tx = "https://hashscan.io/" + network
	url_last_tx += "/transaction/" + last_tx_id


	// php woudl be $last_tx_id_dg = preg_replace("/[^0-9]/", '', $last_tx_id);
	let last_tx_id_dg = sendTxRes.response.transactionId
	last_tx_id_dg = last_tx_id_dg.replaceAll("-", "")
	last_tx_id_dg = last_tx_id_dg.replaceAll(".", "")
	last_tx_id_dg = last_tx_id_dg.replaceAll("@", "")
	//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replaceAll
	//https://stackoverflow.com/a/1144788/7042126
	
	let url_last_tx_dg = (network == 'mainnet') ? 'https://' : 'https://testnet.'
	url_last_tx_dg += "dragonglass.me/transactions/" + last_tx_id_dg
	//https://testnet.dragonglass.me/transactions/0033712931676279208662089605


	let last_tx_id_lworks = sendTxRes.response.transactionId
	// last_tx_id_lworks = last_tx_id_lworks.replace("@", "-")
	// const input = "0.0.3826105@1680613802.855624543";
	const [account_id, start_time] = last_tx_id_lworks.split("@")
	const hyphenated_start_time = start_time.replace(/\./g, "-")
	const txid_lworks = `${account_id}-${hyphenated_start_time}`		
	let url_last_tx_lworks = `https://explore.lworks.io/${network}/transactions/${txid_lworks}`
	let url_last_tx_kpay = `https://kpay.uk/tx/?network=${network}&txid=${txid_lworks}`  // not in use yet


    const hostname = window.location.hostname
    //url_last_tx = (hostname === "kpay.live" || hostname.endsWith(".kpay.live") ?
    url_last_tx = (hostname === "kpay.live" || hostname.endsWith(".kpay.live")) ?
        url_last_tx_kpay : url_last_tx_lworks  // default to lworks if not kpay domain
	
	//https://explore.lworks.io/testnet/transactions/0.0.3826105-1680613802-855624543
	
	// todo review.. sendTxRes.success is the result of signing, not necessarily the tx reaching consensus nor even being submitted?!
	let last_tx_alert_type = (sendTxRes.success) ? 'alert alert-success' : 'alert alert-warning'
	let last_tx_result_message = (sendTxRes.success) ? 'Success!' : 'Error:'
	

    document.getElementById('last_tx').innerHTML = `<BR><div class='${last_tx_alert_type}' role='alert' id='last_tx_result'>${last_tx_result_message} Please wait a moment...</div>`

    setTimeout(function(){

        // document.getElementById('last_tx_result').innerHTML = `${last_tx_result_message} Check the transaction on <a href='${url_last_tx}' target='hashscan'>Hashscan</a>`
        
        //use DG until Hashscan sort themselves out
        // document.getElementById('last_tx_result').innerHTML = `${last_tx_result_message} Check the transaction on <a href='${url_last_tx_dg}' target='DG'>DragonGlass</a>`

        //use lworks, a bit friendlier
        //document.getElementById('last_tx_result').innerHTML = `${last_tx_result_message} Check details on <a href='${url_last_tx_lworks}' target='lworks'>Ledger Works</a>`
        
        //see above logic switch
        document.getElementById('last_tx_result').innerHTML = `${last_tx_result_message} See <a href='${url_last_tx}' target='mirror'>receipt</a>`


        const hostname = window.location.hostname
        let showKpayReceipt = hostname === "kpay.live" || hostname.endsWith(".kpay.live") || urlParamsLocal_HR65476.has("kpay") || document.getElementById("kpay")
        if (showKpayReceipt) {
        //if (new URLSearchParams(window.location.search).has("kpay")) {
            let url_last_tx_kpay = `https://kpay.uk/tx/?network=${network}&txid=${txid_lworks}`
            //https://kpay.uk/tx/?network=testnet&txid=0.0.4043562-1683070701-312662385
    
            document.getElementById('last_tx_result').innerHTML = `${last_tx_result_message} View <a href='${url_last_tx_kpay}' target='kpay'> receipt</a>`
        }

        
    }, 6000)



	// all moved server side
	// let deal_conditions = document.getElementById('deal_conditions').innerHTML 
	// if (typeof(deal_conditions) != 'undefined' && deal_conditions != null) {
		// deals exist
		// document.getElementById('buy-nft').hidden = true // remove the button first
		// post data to php to log Event
		

/* reload not needed - checks are handled server side
		// refresh page to recheck
		setTimeout(function(){
			window.location.reload();
		}, 10000);		 

*/	
	 // }

	//setTimeout (3000)	
	//window.location.reload();

	//let url_last_tx_DG = `` 
//app.drag... for mainnet
//https://testnet.dragonglass.me/hedera/search?q=d9ce1925353444635c5d8c99beeb518cb7522ca7ff3950fe365e975324453f1e3267f2f4aa1c4dd44c04e456425290c7


}


function updateRemainingTime(endTime) {
	var remainingTime = endTime - Math.floor(Date.now() / 1000)

	var hours = Math.floor(remainingTime / 3600)
	var minutes = Math.floor((remainingTime % 3600) / 60)
	var seconds = remainingTime % 60

	var duration = '';
	if (hours > 0) duration += hours + 'h '
	if (minutes > 0) duration += minutes + 'm '
	if (seconds > 0) duration += seconds + 's remaining'

	document.getElementById('time_remaining').innerHTML = duration
	//return duration
}


// ┌─┐┌─┐┬  ┬┌─┐  ╔╦╗┬─┐┌─┐┌┐┌┌─┐┌─┐┌─┐┌┬┐┬┌─┐┌┐┌
// └─┐├─┤└┐┌┘├┤    ║ ├┬┘├─┤│││└─┐├─┤│   │ ││ ││││
// └─┘┴ ┴ └┘ └─┘   ╩ ┴└─┴ ┴┘└┘└─┘┴ ┴└─┘ ┴ ┴└─┘┘└┘
// save Transaction
async function saveTransaction(sendTxRes) {
	let url_save = './vendor/hashconnect/tx_save_result.php'
	const res_save_tx = await fetch(url_save, {
		method: 'POST',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(sendTxRes),
	})
	
	const responseData = await res_save_tx.text()
    console.log('RY76364 result of saving tx...', res_save_tx, responseData)
	return responseData
}

// ┌─┐┌─┐┌┬┐  ╔╗╔┌─┐┌┬┐┌─┐  ╔╗╔┌─┐┌┬┐┌─┐
// │ ┬├┤  │   ║║║│ │ ││├┤   ║║║├─┤│││├┤ 
// └─┘└─┘ ┴   ╝╚╝└─┘─┴┘└─┘  ╝╚╝┴ ┴┴ ┴└─┘
// get Node Name
function getNodeName(nodeString) {
    const nodes = {
        "0.0.3": "LG | Seoul, South Korea 🇰🇷",
        "0.0.4": "Swirlds | Iowa, USA 🇺🇸",
        "0.0.5": "FIS | Florida, USA 🇺🇸",
        "0.0.6": "Wipro | Singapore, Singapore 🇸🇬",
        "0.0.7": "Nomura | Vilnius, Lithuania 🇱🇹",
        "0.0.8": "Google | Helsinki, Finland 🇫🇮",
        "0.0.9": "Zain Group | Kuwait City, Kuwait 🇰🇼",
        "0.0.10": "Magalu | Naaldwijk, Netherlands 🇳🇱",
        "0.0.11": "Boeing | Washington, USA 🇺🇸",
        "0.0.12": "DLA Piper | Helsinki, Finland 🇫🇮",
        "0.0.13": "Tata Communications | Oregon, USA 🇺🇸",
        "0.0.14": "IBM | Texas, USA 🇺🇸",
        "0.0.15": "Deutsche Telekom | Helsinki, Finland 🇫🇮",
        "0.0.16": "UCL | London, UK 🇬🇧",
        "0.0.17": "Avery Dennison | Pennsylvania, USA 🇺🇸",
        "0.0.18": "Dentons | Singapore 🇸🇬",
        "0.0.19": "Standard Bank | Johannesburg, South Africa 🇿🇦",
        "0.0.20": "Australian Payments Plus | Sydney, Australia 🇦🇺",
        "0.0.21": "EDF | Paris, France 🇫🇷",
        "0.0.22": "Shinhan Bank | London, UK 🇬🇧",
        "0.0.23": "Chainlink Labs | Michigan, USA 🇺🇸",
        "0.0.24": "LSE | London, UK 🇬🇧",
        "0.0.25": "IIT Madras | New Jersey, USA 🇺🇸",
        "0.0.27": "ServiceNow | Washington, USA 🇺🇸",
        "0.0.28": "Ubisoft | Quebec, Canada 🇨🇦",
        "0.0.29": "abrdn | London, UK 🇬🇧",
        "0.0.30": "Dell | Frankfurt, Germany 🇩🇪",
        "0.0.31": "COFRA Holding | Frankfurt, Germany 🇩🇪",
        "0.0.32": "Hitachi | Delaware, USA 🇺🇸",
        "0.0.33": "Mondelēz International | Singapore 🇸🇬",
        "0.0.34": "BitGo | Stockholm, Sweden 🇸🇪"
    }

    return nodes[nodeString] || "Unknown node"
}
