export const createOptionForSelect = (value) => {
    const element = document.createElement("option")
    const text = document.createTextNode(value)
    element.appendChild(text)
    return element
}

export const showDisplay = (id) => {
    const element = document.getElementById(id)
    if (element.classList.contains('hide')) {
        document.getElementById(id).classList.remove('hide');
        document.getElementById(id).classList.add('show');
    }

}

export const hideDisplay = (id) => {
    const element = document.getElementById(id)
    if (element.classList.contains('show')) {
        document.getElementById(id).classList.add('hide');
        document.getElementById(id).classList.remove('show');
    }
}

// Asynchronous function to get the config
// we might get rid of this and load from php instead, setting as constants prior to loading bundle
// or the vars can be hard-coded into the page as constants
export async function zzgetConfig() {
    const cachebust = Math.floor(new Date().getTime() / 100000) // Take off milliseconds, plus 2 more to make approx 100 seconds cache
    const pathconfig = './vendor/hashconnect/config.json?t=' + cachebust
    const config = await (await fetch(pathconfig)).json()
    //config = JSON.parse(jsonConfig)
    return config
}


export async function getConfig() {
    const cachebust = Math.floor(new Date().getTime() / 100000) // Take off milliseconds, plus 2 more to make approx 100 seconds cache
    const pathconfig = './vendor/hashconnect/config.json?t=' + cachebust

    // Check if jsonConfig is defined and use it if available
    if (typeof jsonConfig !== 'undefined' && jsonConfig) {
        try {
            const config = JSON.parse(jsonConfig)
            console.log(`TH76364 loading config from global jsonConfig`)
            return config
        } catch (e) {
            console.error('HF87451 Invalid JSON configuration:', e)
            return false
        }
    }

    // If jsonConfig is not available, fetch the configuration file
    try {
        const response = await fetch(pathconfig)
        if (!response.ok) {
            console.error('EU87341 Configuration file not found or error fetching:', response.statusText)
            return false
        }
        console.log(`DW98343 loading config from path ${pathconfig}`)
        const config = await response.json()
        return config
    } catch (error) {
        console.error('WY76344 Error fetching configuration file:', error)
        return false
    }
}



/* i have no idea
export const setUnverifiedAccountId = async (accountId) => {
	// send to server (unverified)
	console.log('sending to server ' + accountId)
	const url = 'setUnverifiedAccount.php?unverifiedAccountId=' + accountId
	const response = await fetch(url)
	
	const json = await response.json() 
	console.log('result from server ' + json)
	return json
}

*/

