import { hashconnect, initHashconnect } from './initHashconnect.js'

export default async () => {

    // Get network from file config.json
    const config = await (await fetch('/vendor/hashconnect/config.json')).json()
    const urlParams = new URLSearchParams(window.location.search)
    const network = urlParams.get('network') ?? config.network

    if (network != config.network) window.alert(`Network has been set to ${network}`)

    // Get userAccountId from session storage
    let userAccountId = urlParams.get('accountId') || false
    let useHashconnect = !userAccountId
    
    if (useHashconnect) {  // try hashconnect 
        const hashconnectSessionData = JSON.parse(window.localStorage.getItem('hashconnectSessionData'))    
        userAccountId = hashconnectSessionData.pairedWallets[hashconnectSessionData.activeWalletIndex]
    }

    // Get the single tokenId from URL
    const tokenId = urlParams.get('tokenId')
    if (!tokenId) {
        console.error('No tokenId provided in the URL')
        return
    }

    // Build the URL for the token associate request
    const params = new URLSearchParams({
        script: 'tokenAssociate',
        accountId: userAccountId,
        tokenId: tokenId
    })

    // Handle optional txid parameter
    const delaySeconds = urlParams.get('delaySeconds')

    let txid
    if (delaySeconds) {
        const currentTimeSeconds = Math.floor(Date.now() / 1000)
        const startSeconds = currentTimeSeconds + parseInt(delaySeconds)
        txid = `${userAccountId}@${startSeconds}.000002025`
        params.append('txid', txid)
    }

    // Construct the full URL
    const url = `/vendor/hashconnect/tx_router.php?${params.toString()}`


    // Fetch the transaction bytes for signing
    const response = await fetch(url)
    const { bytes } = await response.json()


    let dataToSave = {bytes}  // default to unsigned bytes.. but todo could this include pre-signed from kfund?
    // todo map the flow of sigs etc, and consider refactor via kpool services

    if (useHashconnect) {
        // Initialize HashConnect and prepare transaction data
        const initData = await initHashconnect(hashconnect)
        const transData = {
            topic: initData.topic,
            byteArray: bytes,
            metadata: {
                accountToSign: userAccountId,
                returnTransaction: !!delaySeconds // if delay applied then return the tx
            }
        }

        // Ask the user to sign the transaction
        const sendTxRes = await hashconnect.sendTransaction(initData.topic, transData)
        console.log(sendTxRes)

        // Display result status
        if (sendTxRes.success) {
            console.log('Signed successfully')
            dataToSave = sendTxRes // over-write with signed data
        } else console.log('user rejected or error?')
    }

    if (delaySeconds) {
        console.log("dataToSave=", dataToSave)
        // Save the transaction result to the server
        const response = await fetch('/vendor/hashconnect/tx_save_result.php', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToSave),
        })
        
        console.log(`Fetch response status: ${response.status}`)

        document.getElementById('last_tx').innerHTML = `
                <br><br>
                <div class='alert alert-info' role='alert' id='last_tx_result'>
                     Transaction <a href='/future/?network=${network}&txid=${txid}' target='_blank'>${txid}</a> has been scheduled 
                </div>
            `
        return
    }
    
    //not needed for offline..
    
    // Extract and format the transaction ID
    let last_tx_id = sendTxRes.response.transactionId || false
    // if (last_tx_id) {
    last_tx_id = last_tx_id.replace("@", "-")
    last_tx_id = last_tx_id.replace("0.0.", "bollocks to hashscan")
    last_tx_id = last_tx_id.replace(".", "-")
    last_tx_id = last_tx_id.replace("bollocks to hashscan", "0.0.")

    // Build the transaction URL for viewing on Hashscan
    const url_last_tx = `https://hashscan.io/${network}/transaction/${last_tx_id}`

    // Display success or error message on the page
    const last_tx_alert_type = sendTxRes.success ? 'alert alert-success' : 'alert alert-warning'
    const last_tx_result_message = sendTxRes.success ? 'Success!' : 'Error:'
    document.getElementById('last_tx').innerHTML = `
        <br><br>
        <div class='${last_tx_alert_type}' role='alert' id='last_tx_result'>
            ${last_tx_result_message} Please wait a moment...
        </div>
    `

    // Update the transaction message after 4 seconds with a link to Hashscan
  if (last_tx_id)
    setTimeout(() => {
        document.getElementById('last_tx_result').innerHTML = `
            ${last_tx_result_message} Check the transaction on 
            <a href='${url_last_tx}' target='hashscan'>Hashscan</a>
        `
    }, 4000)
}
