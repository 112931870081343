import { hashconnect, initHashconnect, hashconnectSessionData } from './initHashconnect.js'
import { Transaction } from '@hashgraph/sdk'


export default async (bladeSigner) => {
console.log("start  tx_sign.js")
// ┌─┐┌─┐┌┬┐┬ ┬┌─┐┬─┐  ┌─┐┌─┐┬─┐┌─┐┌┬┐┌─┐┌┬┐┌─┐┬─┐┌─┐
// │ ┬├─┤ │ ├─┤├┤ ├┬┘  ├─┘├─┤├┬┘├─┤│││├┤  │ ├┤ ├┬┘└─┐
// └─┘┴ ┴ ┴ ┴ ┴└─┘┴└─  ┴  ┴ ┴┴└─┴ ┴┴ ┴└─┘ ┴ └─┘┴└─└─┘
// gather parameters needed to process the request
// bit scattered, could refactor later
    
    //const config = await (await fetch('./vendor/hashconnect/config.json')).json()
    /*
	// get network from file config.json
	const config = await (await fetch('./vendor/hashconnect/config.json')).json()
	// todo .. refactor and get network from external function ref GJ87765 see below
	const urlParams = new URLSearchParams(window.location.search);	
	const network = urlParams.get('network') ?? config.network

	if (network != config.network) window.alert(`Network has been set to ${network}`)

    */

    //but using local meanwhile.. todo refactor
	const urlParamsLocal_DU76453 = new URLSearchParams(window.location.search)
    
	// get txid from URL	
	let txid = null
	if (urlParamsLocal_DU76453.has('txid')) txid = urlParamsLocal_DU76453.get('txid')
		else console.log('missing txid')

	// get userAccountId from session storage 
    const hashconnectSessionData = JSON.parse(window.localStorage.getItem('hashconnectSessionData'))
	//let userAccountId = hashconnectSessionData.pairedWallets[hashconnectSessionData.activeWalletIndex]
	let userAccountId = hashconnectSessionData
		? hashconnectSessionData.pairedWallets[hashconnectSessionData.activeWalletIndex]
		: bladeSigner.getActiveAccountId()

	// check for any form data to attach
	const user_notes = document.getElementById("user_notes");
	//if (!user_notes) ....

    const data = {
        network: network,
        userAccountId: userAccountId,
		txid: txid,
		user_notes: user_notes
    }

// ┌─┐┌─┐┌┬┐  ┌┬┐─┐ ┬
// │ ┬├┤  │    │ ┌┴┬┘
// └─┘└─┘ ┴    ┴ ┴ └─
// get tx

	// pass network, userAccountId, and dealID to the signer
	const api_sign = './vendor/hashconnect/tx_recall.php'	
    const response = await fetch(api_sign, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    })

    //const { bytes } = await response.json()  // get the transaction ready for signing
	const result = await response.json()  // get the transaction ready for signing
	console.log("get bytes result=", result)
	if (result.hasOwnProperty('error')) {
		console.log('error ' + result.error)
	
		let error_message = result.error
		document.getElementById('last_tx').innerHTML = `<BR><BR><div class='alert alert-warning' role='alert' id='last_tx_result'>${error_message}</div>`	
		throw new Error();
		
	}


// todo create a simple page to set the txid in the url, and call this to execute

	//console.log("result=", result)
	const bytes = result; // i think bytes is at the root
	//const { bytes } = result;
	//console.log(bytes)


// ┌─┐┌─┐┌┐┌┌┬┐  ┌┬┐─┐ ┬  ┌┬┐┌─┐  ┬ ┬┌─┐┌─┐┬ ┬┌─┐┌─┐┌─┐┬┌─
// └─┐├┤ │││ ││   │ ┌┴┬┘   │ │ │  ├─┤├─┤└─┐├─┤├─┘├─┤│  ├┴┐
// └─┘└─┘┘└┘─┴┘   ┴ ┴ └─   ┴ └─┘  ┴ ┴┴ ┴└─┘┴ ┴┴  ┴ ┴└─┘┴ ┴
//or send to blade
// same code as in transaction.js.. todo refactor and import

	let sendTxRes
	// send tx to hashpack
	if (hashconnectSessionData) {
		console.log("Paying with hashconnect")
		// initialise hashconnect obj and get returned data
		const initData = await initHashconnect(hashconnect)
			
		const transData = {
			topic: initData.topic,
			byteArray: bytes,
			metadata: {
				accountToSign: hashconnectSessionData.pairedWallets[hashconnectSessionData.activeWalletIndex],
				returnTransaction: true
			}
		}

		// ask the user to sign
		sendTxRes = await hashconnect.sendTransaction(initData.topic, transData)
	} 
	// handle tx with blade
	else {
		console.log("Paying with blade")   // todo, refactor how to organise Hashpack vs Blade
		//const tx = Transaction.fromBytes(new Uint8Array(bytes.data))
		const tx = Transaction.fromBytes(new Uint8Array(bytes))

        console.log("sign only with blade - tbc untested")
        const [signedTransaction, transactionId] = await bladeSigner.signAndReturnBytes(tx).catch(err => console.error("Error returning bytes"))

        sendTxRes = { // roughly match hashconnect response
            //network: network,  // not needed, as server side php has this
            success: true, //? tbc
            transactionId,
            signedTransaction,
            raw: JSON.stringify({transactionId, signedTransaction}) // debug only
        }

	}
    
	console.log(sendTxRes)
	
    sendTxRes.success
        ? console.log('Signed successfully')
        : console.log('Error')
	
	

// ┌─┐┌─┐┬  ┬┌─┐  ┬─┐┌─┐┌─┐┌─┐┌─┐┌┐┌┌─┐┌─┐
// └─┐├─┤└┐┌┘├┤   ├┬┘├┤ └─┐├─┘│ ││││└─┐├┤ 
// └─┘┴ ┴ └┘ └─┘  ┴└─└─┘└─┘┴  └─┘┘└┘└─┘└─┘
// save response if scheduled

//hmm we need to update tx_save_result.php to send data back to saas
	// save on the server
    let url_save = './vendor/hashconnect/tx_save_result.php'
    const res_save_tx = await fetch(url_save, {

        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(sendTxRes),
    })

    console.log(`Raw response from ${url_save}`, res_save_tx)

    const json = await res_save_tx.json()
    // Log the parsed JSON
    console.log('json=', json)
    
    // alert user of result
    //let user_message = 'GF65432' 
    //
    //document.getElementById('last_tx').innerHTML = `<BR><BR><div class='alert alert-primary' role='alert' id='last_tx_result'>${user_message}</div>`
		
        
    // reload the page to show changes?
    if (sendTxRes.success) location.reload()


}
