import { hashconnect, initHashconnect, hashconnectSessionData } from './initHashconnect.js'
// import { HashConnect } from 'hashconnect'

export default async () => {

	// get network from file config.json
	const config = await (await fetch('./vendor/hashconnect/config.json')).json()
	// todo .. refactor and get network from external function ref GJ87765
	const urlParams = new URLSearchParams(window.location.search);	
	const network = urlParams.get('network') ?? config.network

	if (network != config.network) window.alert(`Network has been set to ${network}`)

	// get userAccountId from session storage 
    const hashconnectSessionData = JSON.parse(window.localStorage.getItem('hashconnectSessionData'))
	let userAccountId = hashconnectSessionData.pairedWallets[hashconnectSessionData.activeWalletIndex]

	// get user request from URL
	
	let url = './vendor/hashconnect/tx_router.php?'
	url += 'script=tokenDissociate' // maybe later make generic  //+ urlParams.get('script')
	url += '&accountId=' + userAccountId
	//url += '&tokenId=' + urlParams.get('tokenId')
    
    let tokenIds = urlParams.getAll('tokenId')
    tokenIds.forEach(tokenId => {
        url += '&tokenId=' + tokenId
    })
	
	//console.log("calling url " + url)
	
	const response = await fetch(url)

    const { bytes } = await response.json()  // get the transaction ready for signing

    // initialise hashconnect obj and get returned data
    const initData = await initHashconnect(hashconnect)

    const transData = {
        topic: initData.topic,
        byteArray: bytes,
        metadata: {
            accountToSign: hashconnectSessionData.pairedWallets[hashconnectSessionData.activeWalletIndex],
            returnTransaction: false
        }
    }

	// ask the user to sign
    const sendTxRes = await hashconnect.sendTransaction(initData.topic, transData)


    sendTxRes.success
        ? console.log('Signed successfully')
        : console.log('Error')
	
	console.log(sendTxRes)
	
	//window.localStorage.setItem('last_tx_result', JSON.stringify(sendTxRes))
	//let last_tx_result = JSON.parse(window.localStorage.getItem('tx_result'))

	last_tx_id = sendTxRes.response.transactionId
	
	last_tx_id = last_tx_id.replace("@", "-")
	last_tx_id = last_tx_id.replace("0.0.", "bollocks to hashscan")
	last_tx_id = last_tx_id.replace(".", "-")
	last_tx_id = last_tx_id.replace("bollocks to hashscan", "0.0.")

	url_last_tx = "https://hashscan.io/" + network
	url_last_tx += "/transaction/" + last_tx_id
	
	let last_tx_alert_type = (sendTxRes.success) ? 'alert alert-success' : 'alert alert-warning'
	let last_tx_result_message = (sendTxRes.success) ? 'Success!' : 'Error:'
	
	document.getElementById('last_tx').innerHTML = `<BR><BR><div class='${last_tx_alert_type}' role='alert' id='last_tx_result'>${last_tx_result_message} Please wait a moment...</div>`

	// save on the server
	const res_save_tx = await fetch('./vendor/hashconnect/tx_save_result.php', {

        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(sendTxRes),
    })

	
	//setTimeout (3000)	
	setTimeout(function(){
		//document.getElementById('price_panel').innerHTML = `Check the transaction on <a href='${url_last_tx}' target='hashscan'>Hashscan</a>`
		document.getElementById('last_tx_result').innerHTML = `${last_tx_result_message} Check the transaction on <a href='${url_last_tx}' target='hashscan'>Hashscan</a>`
		//console.log("Hello World");
	}, 4000);
		
	
	//setTimeout (3000)	
	//window.location.reload();

	//let url_last_tx_DG = `` 
//app.drag... for mainnet
//https://testnet.dragonglass.me/hedera/search?q=d9ce1925353444635c5d8c99beeb518cb7522ca7ff3950fe365e975324453f1e3267f2f4aa1c4dd44c04e456425290c7


}
