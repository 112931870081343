// import { HashConnect } from 'hashconnect'

import BladeSigner from './blade/BladeSigner.js'
import pairHashpack from './pairHashpack.js'
import transaction from './transaction.js'
import tx_sign from './tx_sign.js'
import authenticate from './authenticate.js'
import tx_spender_allowance from './tx_spender_allowance.js'
import tx_associate from './tx_associate.js'
import tx_dissociate from './tx_dissociate.js'
import tx_account_update from './tx_account_update.js'
import { createOptionForSelect, showDisplay, hideDisplay } from './utils.js'

//if (typeof urlParams == 'undefined')
const urlParams = new URLSearchParams(window.location.search)


// ┌┐┌┌─┐┌┬┐┬ ┬┌─┐┬─┐┬┌─
// │││├┤  │ ││││ │├┬┘├┴┐
// ┘└┘└─┘ ┴ └┴┘└─┘┴└─┴ ┴
// network can be defined globally

const allowNetworkOverride = true

if (network) network = cleanNetwork(network)
else console.log('network was not set prior to loading bundle.. going to set it here...')

if (network) 
    console.log(`network = ${network} has already been set globally`)

// Check if 'productId' parameter is missing
const productId = urlParams.get('productId')
if (allowNetworkOverride && productId) {
    // Split the 'productId' parameter into three parts using the semicolon (;) as the delimiter
    //const [network, token, owner] = productId.split(';')
    [network] = productId.split(';')
    network = cleanNetwork(network)
    
    console.log(`network = ${network} set from productId=${productId}`)
} 

if (allowNetworkOverride && network) {
    //network = cleanNetwork(urlParams.get('network')) || network
    // same but explicit...
    let urlNetwork = cleanNetwork(urlParams.get('network'))
    if (urlNetwork) {
        network = urlNetwork
        console.log(`network = ${network} set from url`)
    }
}


if (!network) { // debug use
    network = 'testnet'
    console.dir('warning:network defaulted to testnet ref FH76432')
    alert('warning: network defaulted to testnet ref FH76432')
}


function cleanNetwork(network) {
    if (!network) return ''
    if (!['mainnet', 'testnet'].includes(network)) {
  //if (!/^(mainnet|testnet)$/.test(network)) {  // same with regex

        const error = new Error()
        const stackLines = error.stack.split('\n')
        const callerLine = stackLines[2].trim()
        console.log(`warning - removed invalid network '${network}' at ${callerLine}`)
        //console.log(`warning - removed invalid network '${network}'`)
        return ''
    }
    return network
}



// blade seems to be all messed up now
// redirects to walletconnect, and the blade app wont scan qr.
// also poor ui consider removing
//if (urlParams.has('blade')
    const bladeSigner = new BladeSigner(network)

// if (typeof productId !== 'undefined') console.dir({productId})
if (bladeSigner) console.dir({bladeSigner})


// ┌─┐┌┐┌  ┌─┐┌─┐┌─┐┌─┐  ┬  ┌─┐┌─┐┌┬┐
// │ ││││  ├─┘├─┤│ ┬├┤   │  │ │├─┤ ││
// └─┘┘└┘  ┴  ┴ ┴└─┘└─┘  ┴─┘└─┘┴ ┴─┴┘
let selectAccountId // the currently selected user account ID
window.addEventListener('load', (e) => {
    e.preventDefault()
	console.log('load listener triggered')

	//if (bladeSigner) console.log('bladeSigner=', bladeSigner)	

	// if (bladeSigner) {
		// let bladeAccount = bladeSigner.getActiveAccountId()
		// console.log(`bladeAccount = ${bladeAccount}`)
	// } else console.log('bladeSigner is undefined')
	
    let hashconnectSessionData = localStorage.getItem("hashconnectSessionData")

    if (hashconnectSessionData) {
		console.log('hashconnectSessionData=', hashconnectSessionData)
        // show / hide relevant buttons
        hideDisplay('before-pair')
        hideDisplay('pairing')
        showDisplay('paired')
        // fill in text
        hashconnectSessionData = JSON.parse(hashconnectSessionData)
		selectAccountId = document.getElementById('account-id')
        for (let account of hashconnectSessionData.pairedWallets) {
            // add account id to drop down menu
            const element = createOptionForSelect(account)
            selectAccountId.appendChild(element)
        }

        //restore the selected account from session
		selectAccountId.value = selectAccountId[hashconnectSessionData.activeWalletIndex].value


        document.dispatchEvent(new CustomEvent('eventUserSelectsAccount', {
            detail: { accountId: selectAccountId.value, action: 'refresh' }
        }))
                    
        console.log(`user refreshed page, account is ${selectAccountId.value}`)
		
        window.localStorage.setItem('hashconnectSessionData', JSON.stringify(hashconnectSessionData))
    } else {
        console.log("No hashpack data")
    }
})


// ┌─┐┌─┐┬┬─┐  ┬ ┬┌─┐┬  ┬  ┌─┐┌┬┐
// ├─┘├─┤│├┬┘  │││├─┤│  │  ├┤  │ 
// ┴  ┴ ┴┴┴└─  └┴┘┴ ┴┴─┘┴─┘└─┘ ┴ 
// Handle pair wallet function
const pairBtn = document.querySelectorAll('#pair-btn')
pairBtn?.forEach(e => {
    e.addEventListener('click', async () => {
        const saveData = await pairHashpack()
        const div = document.getElementById('pairing-string')
        div.innerHTML = saveData.pairingString
		// fetch png of QR code
        const url_fetch_png = './vendor/hashconnect/getQR.php?' + saveData.pairingString
        const url_png = (await (await fetch(url_fetch_png)).json()).url_png
        document.getElementById('pairing-png').innerHTML = `<br><img src='${url_png}' width=200>`
    })
})


// ┌─┐┌┐┌  ┌─┐┌─┐┬  ┌─┐┌─┐┌┬┐  ┌─┐┌─┐┌─┐┌─┐┬ ┬┌┐┌┌┬┐  ┬┌┬┐
// │ ││││  └─┐├┤ │  ├┤ │   │   ├─┤│  │  │ ││ ││││ │   │ ││
// └─┘┘└┘  └─┘└─┘┴─┘└─┘└─┘ ┴   ┴ ┴└─┘└─┘└─┘└─┘┘└┘ ┴   ┴─┴┘
// on select account id
selectAccountId = document.getElementById('account-id')

// Handle change in active account
selectAccountId?.addEventListener('change', (e) => {
    let hashconnectSessionData = JSON.parse(window.localStorage.getItem('hashconnectSessionData'))
	
	// update session data active wallet
    hashconnectSessionData.activeWalletIndex = e.target.selectedIndex
	
	// set session data
    window.localStorage.setItem('hashconnectSessionData', JSON.stringify(hashconnectSessionData))

    let selectedAccountId = selectAccountId[hashconnectSessionData.activeWalletIndex].value

    //create event to share with other other processes
    document.dispatchEvent(new CustomEvent('eventUserSelectsAccount', {
        detail: { accountId: selectedAccountId, action: 'switch' }
    }))
                
    //document.dispatchEvent(new CustomEvent('eventUserSelectsAccountId', { detail: { accountId: selectedAccountId }}))
    console.log(`user selects another account: ${selectedAccountId}`)
           
           
	// copy result to server (not secure, for info only)
    const resultSetUnverifiedAccount = fetch('./vendor/hashconnect/setUnverifiedAccount.php?unverifiedAccountId=' + selectedAccountId)
})


// ┌─┐┌─┐┬┬─┐  ┌┐ ┬  ┌─┐┌┬┐┌─┐
// ├─┘├─┤│├┬┘  ├┴┐│  ├─┤ ││├┤ 
// ┴  ┴ ┴┴┴└─  └─┘┴─┘┴ ┴─┴┘└─┘
// Blade wallet connection
const pairBladeBtn = document.querySelectorAll('#pair-blade-btn')
pairBladeBtn?.forEach(e => {
    e.addEventListener('click', async () => {
        console.log("Pairing blade wallet")
        try {
			// clear local storage before connecting Blade (as only one connection allowed)
            clearLocalStorage()
            let activeBladeAccount = await bladeSigner.pairBlade()
            console.log("Active blade acct: ", activeBladeAccount)
			
			// populate select element with account id
            const accountIdSelect = document.getElementById('account-id')
            const element = createOptionForSelect(activeBladeAccount)
            accountIdSelect.appendChild(element)
            
                    
            hideDisplay('before-pair')
            showDisplay('paired')
            console.log("paired")
        } catch (e) {
            console.error("Error pairing blade wallet")
            console.error(e)
		}
        
    })
})


// ┬ ┬┌─┐┬  ┬  ┌─┐┌┬┐  ┌─┐┌─┐┌┬┐┬┌─┐┌┐┌  ┌─┐┬  ┬┌─┐┌┐┌┌┬┐┌─┐
// │││├─┤│  │  ├┤  │   ├─┤│   │ ││ ││││  ├┤ └┐┌┘├┤ │││ │ └─┐
// └┴┘┴ ┴┴─┘┴─┘└─┘ ┴   ┴ ┴└─┘ ┴ ┴└─┘┘└┘  └─┘ └┘ └─┘┘└┘ ┴ └─┘
// wallet action events

// create tx from a deal template. attach to og button 'buy-nft' or more generic 'create-tx'
const createTx = document.getElementById('create-tx') || document.getElementById('buy-nft')
createTx?.addEventListener('click', () => transaction(bladeSigner))

document.getElementById('txSign')?.addEventListener('click', () => tx_sign(bladeSigner))

document.getElementById('auth-btn')?.addEventListener('click', authenticate)
document.getElementById('approve-spend-btn')?.addEventListener('click', tx_spender_allowance)
document.getElementById('associate-btn')?.addEventListener('click', tx_associate)
document.getElementById('dissociate-btn')?.addEventListener('click', tx_dissociate)

// park... not supported with hashconnect https://discord.com/channels/888550498133766204/913068258503065690/1044361139292737657
document.getElementById('account-update-btn')?.addEventListener('click', tx_account_update)




// ┌┬┐┬┌─┐┌─┐┌─┐┌┐┌┌┐┌┌─┐┌─┐┌┬┐  ┬ ┬┌─┐┬  ┬  ┌─┐┌┬┐
 // │││└─┐│  │ │││││││├┤ │   │   │││├─┤│  │  ├┤  │ 
// ─┴┘┴└─┘└─┘└─┘┘└┘┘└┘└─┘└─┘ ┴   └┴┘┴ ┴┴─┘┴─┘└─┘ ┴ 
//disconnect wallet
document.getElementById('disconnect')?.addEventListener('click', disconnectWallets)

function disconnectWallets() {
    if (!confirm('Disconnect now?')) return
	// Clear localStorage
	clearLocalStorage()
    window.location.reload()
}

function clearLocalStorage() {
	// Clear localStorage
    window.localStorage.removeItem("hashconnectData")  // tbc which is being used
    window.localStorage.removeItem("hashconnectSessionData") //
    //alert('Local Storage Cleared')
}

