import { hashconnect, initHashconnect } from './initHashconnect.js'
import { createOptionForSelect, showDisplay, hideDisplay, } from './utils.js'

// import { HashConnect } from 'hashconnect'


let hashconnectSessionData = localStorage.getItem('hashconnectSessionData')
if (hashconnectSessionData) 
    hashconnectSessionData = JSON.parse(hashconnectSessionData)
else 
	hashconnectSessionData = {
    pairedWallets: [],
    activeWalletIndex: undefined
}

export default async () => {
    const initData = await initHashconnect(hashconnect)
    // hide old button
    hideDisplay('before-pair')
    // display pairing block
    showDisplay('pairing')

    // send ping for pairing on hashpack
    hashconnect.foundExtensionEvent.once((walletMetadata) => {
        walletMetadata
            ? hashconnect.connectToLocalWallet(initData.pairingString, walletMetadata)
            : window.open('https://chrome.google.com/webstore/detail/hashpack/gjagmgiddbbciopjhllkdnddhcglnemk', '_blank').focus();
    })
    // after successful pairing
    hashconnect.pairingEvent.once(pairingData => {
        hideDisplay('pairing')
        showDisplay('paired')
        // get select account element
        const accountIdSelect = document.getElementById('account-id')
        // loop through pairing data
        pairingData.accountIds.forEach(id => {
            // check if accountId has been paired already
            if (hashconnectSessionData.pairedWallets.indexOf(id) === -1) {
                // add accountId to paired accounts array
                hashconnectSessionData.pairedWallets.push(id);
                // add account id to drop down menu
                const element = createOptionForSelect(id)
                accountIdSelect.appendChild(element)
                // change active account to most recent account
                hashconnectSessionData.activeWalletIndex = hashconnectSessionData.pairedWallets.length - 1
                // Change select to display newly added option
                accountIdSelect.value = accountIdSelect[hashconnectSessionData.activeWalletIndex].value
                
                //create event to share with other other processes
                document.dispatchEvent(new CustomEvent('eventUserSelectsAccount', {
                    detail: { accountId: accountIdSelect.value, action: 'add' }
                }))

                // document.dispatchEvent(new CustomEvent('eventUserAddsAndSelectsAccountId', { detail: { accountId: accountIdSelect.value }}))
                console.log(`new user account added ${accountIdSelect.value}`)
                
				const dummy = fetch('./vendor/hashconnect/setUnverifiedAccount.php?unverifiedAccountId=' + accountIdSelect.value)
	
            } else {
                alert('Already paired this account!')
            }
        })

		window.localStorage.setItem('hashconnectSessionData', JSON.stringify(hashconnectSessionData))
    })


    return initData
}


const loadLocalData = () => {
    const foundData = localStorage.getItem('hashconnectData')
    if (foundData) {
        saveData = JSON.parse(foundData)
        return true
    } else {
        return false
    }
}